<template>
  <div class="d-flex align-items-stretch g-0">
    <ActivityImage
      class="m-0"
      :activityIcon="activityIcon"
      :activityBgColor="activityBgColor"
      :activityStrokeColor="activityStrokeColor"
      :square="true"
    />
    <p class="text-infos w-100 m-0 pl-4 d-flex align-items-center">
      {{ currentTour.activityType_designation }}
    </p>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

import ActivityImage from "@/components/activity/list/ActivityImage";

export default {
  components: {
    ActivityImage
  },
  computed: {
    ...mapGetters({
      currentTour: "tour/currentEntry",
    }),
    activityIcon() {
      return this.currentTour.activityType_iconPath;
    },
    activityBgColor() {
      return this.currentTour.activityType_iconBGColor;
    },
    activityStrokeColor() {
      return this.currentTour.activityType_iconStrokeColor;
    },
    activityType() {
      return this.currentTour.activityType_designation;
    },
  }
};
</script>

<style lang="scss" scoped></style>
